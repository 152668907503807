/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}


@font-face {
  font-family: "Formula1";
  src: url("./fonts/Formula1-Bold.ttf");
}

@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;700&display=swap');

#container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Navbar */
h1 {
  font-family: "Formula1", sans-serif;
  font-size: 34px;
  color: #FFF;
  text-align: center;
}

#nav {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84px;
  background-color: #E10600;
}

/* Start Screen */
h3 {
  font-family: "Titillium Web", sans-serif;
  font-weight: bold;
  font-size: 34px;
  text-align: center;
  margin-top: 110px;
}

#startScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

#start,
#tryAgain {
  background-color: #E10600;
  color: #FFF;
  border: 4px solid #E10600;
  border-radius: 10px;
  font-family: "Titillium Web", sans-serif;
  font-weight: bold;
  font-size: 34px;
  margin-top: 25px;
  padding: 0 56.5px;
  width: max-content;
  transition: all 0.15s;
}

#start:hover,
#tryAgain:hover {
  background-color: #FFF;
  color: #15151E;
}

/* Game Screen */
#gameScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

#cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
  width: 85vw;
}

h4 {
  font-family: "Titillium Web", sans-serif;
  font-weight: bold;
  font-size: 34px;
  text-align: center;
}

.marginT25 {
  margin-top: 25px;
}

.marginB20 {
  margin-bottom: 20px;
}

/* Card */
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 226px;
  height: 300px;
  background-color: #FFF;
  border: 2px solid #15151E;
  border-radius: 15px;
}

.topCard {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 10px 0 10px;
}

.flag {
  border: 1.5px solid #15151E;
  border-radius: 5px;
  width: 48px;
}

.iconBtn {
  width: 26px;
  background: none;
  border: none;
}

.icon {
  width: 26px;
  height: 26px;
}

.track {
  height: 150px;
  margin-top: 25px;
  margin-bottom: 20px;
}

.track:hover {
  cursor: pointer;
}

h2 {
  font-family: "Formula1", sans-serif;
  font-size: 25px;
  color: #15151E;
}

/* Track Info Popup */
#trackPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFF;
  border: 5px solid #15151E;
  border-radius: 20px;
  width: 400px;
  height: 460px;
  margin-bottom: 25px;
}

#trackInfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 15px;
}

h5 {
  font-family: "Titillium Web", sans-serif;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 0;
}

h6 {
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 0;
}

p {
  font-family: "Titillium Web", sans-serif;
}

#trackInfoText {
  margin-top: -10px;
}

#infoIcon,
#trackInfoSpacer {
  width: 26px;
  height: 26px;
}

.hidden {
  display: none !important;
}

/* Game Over Screen */
#gameOverScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

/* Game Won Screen */
#gameWonScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

/* Footer */
a {
  text-decoration: none;
  font-family: "Titillium Web", sans-serif;
  font-size: 20px;
  color: #FFF;
}

#footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
  background-color: #15151E;
  height: 77px;
}

@media screen and (max-width: 1750px) {
  #cards {
    gap: 25px;
    width: 95vw;
  }
}